<template>
  <div>
    <h2>cURL转原生代码</h2>
    <a-row>
      <a-col :span="11">
        <a-textarea
          placeholder="请将cURL命令粘贴进来"
          style="height:500px;"
          @change="rawOnChange"
          v-model="rawData"
        />
      </a-col>

      <a-col class="control" :span="2" style="text-align:center">
        <!-- <a-button v-on:click="transClick">转换</a-button> -->
        <span>转换为：</span>
        <a-radio-group v-model="value" @change="onChange" style="text-align:left">
          <a-radio :style="radioStyle" :value="1">Python</a-radio>
          <a-radio :style="radioStyle" :value="2">Node.js</a-radio>
          <a-radio :style="radioStyle" :value="3">Go</a-radio>
          <a-radio :style="radioStyle" :value="4">PHP</a-radio>
          <a-radio :style="radioStyle" :value="5">Rust</a-radio>
          <a-radio :style="radioStyle" :value="6">R</a-radio>
          <a-radio :style="radioStyle" :value="7">JsonString</a-radio>
          <a-radio :style="radioStyle" :value="8">Dart</a-radio>
        </a-radio-group>
      </a-col>

      <a-col :span="11">
        <ToolRightWrapper :transData="transData" />
      </a-col>
    </a-row>

    <div class="content">
      <h2>使用方法</h2>
      <a-row>
        <ol class="use-direction">
          <a-col :span="8">
            <li>从 Firefox、Chrome 的控制台复制 cURL 命令</li>

            <div>
              <p style="margin:0.1rem;font-size:15px">
                <b>Firefox:</b>
              </p>
              <img src="https://images.alisen39.com/20200526003632.png" alt />
            </div>

            <div>
              <p style="margin:0.1rem;font-size:15px">
                <b>Chrome:</b>
              </p>
              <img src="https://images.alisen39.com/20200526004217.png" alt />
            </div>
          </a-col>

          <a-col :span="15" :offset="1">
            <li>将其粘贴至本页面左边窗口，选择中间的目标语言，即可转换成其原生代码</li>
            <img src="https://images.alisen39.com/20200526004353.png" alt />
          </a-col>
        </ol>
      </a-row>
    </div>
  </div>
</template>


<script>
import Vue from 'vue'
import Radio from 'ant-design-vue'

import ToolRightWrapper from '../components/ToolRightWrapper.vue'
var curlconverter = require('curlconverter')

Vue.use(Radio)
const mp = {
  1: curlconverter.toPython,
  2: curlconverter.toNode,
  3: curlconverter.toGo,
  4: curlconverter.toPhp,
  5: curlconverter.toRust,
  6: curlconverter.toR,
  7: curlconverter.toJsonString,
  8: curlconverter.toDart
}

export default {
  name: 'CurlToCode',
  components: {
    ToolRightWrapper
  },
  metaInfo: {
    title: 'curl命令转Python、Go、Node.js、Dart等原生代码 | 麦麦麦造的小站', // set a title
    meta: [
      {
        // set meta
        name: 'keyWords',
        content: 'curl,python,python3,go,Node.js,Dart'
      },
      {
        name: 'description',
        content: '快速的将curl的命令转Python、Go、Node.js、Dart等原生代码'
      }
    ]
  },
  data() {
    return {
      value: 1,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      rawData: '',
      // "curl 'http://en.wikipedia.org/' -H 'Accept-Encoding: gzip, deflate, sdch' -H 'Accept-Language: en-US,en;q=0.8' -H 'User-Agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.95 Safari/537.36' -H 'Accept: text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8' -H 'Referer: http://www.wikipedia.org/' -H 'Cookie: GeoIP=US:Albuquerque:35.1241:-106.7675:v4; uls-previous-languages=%5B%22en%22%5D; mediaWiki.user.sessionId=VaHaeVW3m0ymvx9kacwshZIDkv8zgF9y; centralnotice_buckets_by_campaign=%7B%22C14_enUS_dsk_lw_FR%22%3A%7B%22val%22%3A%220%22%2C%22start%22%3A1412172000%2C%22end%22%3A1422576000%7D%2C%22C14_en5C_dec_dsk_FR%22%3A%7B%22val%22%3A3%2C%22start%22%3A1417514400%2C%22end%22%3A1425290400%7D%2C%22C14_en5C_bkup_dsk_FR%22%3A%7B%22val%22%3A1%2C%22start%22%3A1417428000%2C%22end%22%3A1425290400%7D%7D; centralnotice_bannercount_fr12=22; centralnotice_bannercount_fr12-wait=14' -H 'Connection: keep-alive' --compressed",
      transData: ''
    }
  },
  methods: {
    onChange(e) {
      this.$data.transData = mp[e.target.value](this.$data.rawData)
    },
    rawOnChange() {
      this.$data.transData = mp[this.$data.value](this.$data.rawData)
    }
  }
}
</script>

<style >
textarea {
  resize: none;
}
.control label {
  margin-left: 10%;
}
</style>